import { useState, useEffect } from "react";
import { useAuthInfo } from "@propelauth/react";
import { Card, Dialog, DialogActions, DialogContent, DialogTitle, Button, Alert, CircularProgress, TextField, IconButton, Icon } from '@mui/material';
import { blue } from '@mui/material/colors';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { lightBlue } from '@mui/material/colors';
import { useBrand } from 'layouts/utils/BrandContext';
import { useParams } from 'react-router-dom';
import axios from 'axios';

function DataReadinessTab() {
    const { selectedBrand, selectedRefresh, user_email, loadContext } = useBrand();
    const [loading, setLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState({ show: false, message: '', color: '' });
    const [readinessResults, setReadinessResults] = useState(null);
    const { brandName, refreshName } = useParams();
    const auth = useAuthInfo();

    useEffect(() => {
        const fetchContext = async () => {
            if (brandName && refreshName && !selectedBrand && !selectedRefresh) {
                await loadContext(brandName, refreshName);
            }
        };
        fetchContext();
    }, [brandName, refreshName, loadContext]);

    // Automatically call handleDataReadiness when context is set
    useEffect(() => {
        if (selectedBrand && selectedRefresh) {
            handleDataReadiness();
        }
    }, [selectedBrand, selectedRefresh]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleDataReadiness = async () => {
        setLoading(true);
        const model_inputs_path = `${user_email}/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/model_inputs.json`;
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const fullUrl = `${baseUrl}/data_configuration/check_data_readiness`;

        const formData = new FormData();
        formData.append('model_inputs_path', model_inputs_path);
        try {
            const response = await axios.post(fullUrl, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${auth.accessToken}`
                }
            });
            if (response.data) {
                setReadinessResults(response.data);
                //console.log('Data readiness:', JSON.stringify(response.data, null, 2));
            }
        } catch (error) {
          console.error('Error checking data readiness:', error);
          setAlert({ show: true, message: `${error.response.data.detail}`, color: 'error' });
          setOpenDialog(true);
        } finally {
          setLoading(false);
        }
      };

    return (
       <>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>{alert.color === 'success' ? 'Success' : 'Error'}</DialogTitle>
                <DialogContent>
                  {alert.show && <Alert color={alert.color}>{alert.message}</Alert>}
                </DialogContent>
                <DialogActions style={{ justifyContent: 'flex-end' }}>
                  <Button onClick={handleCloseDialog} color="primary" sx={{'&:hover': {backgroundColor: 'rgba(0, 0, 0, 0.08)',}}}>
                    Close
                  </Button>
                </DialogActions>
            </Dialog>
          <MDBox p={3} pb={3} pr={5}>
            <MDTypography variant="h3">Data Readiness</MDTypography>

            <Card sx={{ mt:2, pb:2, pl:3, pr:3, pt:0}}>
             {!selectedBrand || !selectedRefresh ? (
                        <MDTypography variant="h6" sx={{ color: 'red', mt: 3 }}>
                            Please select a Brand and Refresh first in order to view the data readiness.
                        </MDTypography>
                    ) : loading ? (
                    <MDBox sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                        <CircularProgress sx={{ color: blue[500] }} />
                    </MDBox>
                ) : readinessResults && (
                    <>
                        <MDTypography variant="h4" sx={{ mt: 3 }}>Readiness Results</MDTypography>
                        <MDTypography variant="h5" sx={{ color: blue[700], fontWeight: 'bold' }}>
                            Final Score: {readinessResults.final_score.toFixed(2)}
                        </MDTypography>
                        <MDBox sx={{ mt: 2 }}>
                            {readinessResults.readiness_results.map(([category, details], index) => (
                                <Card key={index} sx={{ p: 2, mb: 2 }}>
                                    <MDTypography variant="h5">{category}</MDTypography>
                                    <MDTypography variant="normal">
                                        Importance: {details.importance}
                                    </MDTypography>
                                    <MDTypography variant="normal" sx={{ color: blue[700], fontWeight: 'bold' }}>
                                        Score: {details.score} / Weight: {details.weight}
                                    </MDTypography>
                                    <MDTypography variant="normal">
                                        Time Taken: {details.time_taken.toFixed(4)} seconds
                                    </MDTypography>
                                    <MDBox sx={{ mt: 1 }}>
                                        {details.messages.map((message, idx) => (
                                            <MDTypography key={idx} variant="normal" color={message.type === 'success' ? 'green' : message.type === 'warning' ? 'orange' : 'red'}>
                                                {message.text}
                                            </MDTypography>
                                        ))}
                                    </MDBox>
                                </Card>
                            ))}
                        </MDBox>
            </>
          )}
           </Card>
        </MDBox>
        </>
    );
}

export default DataReadinessTab;